const TaskCardDeleteButton = ({
  taskCard,
  taskCardsList,
  setTaskCardsList,
}) => {
  const taskCardDeleteButton = (id) => {
    setTaskCardsList(taskCardsList.filter((card) => card.id !== id));
  };
  return (
    <div>
      <button
        className="taskCardDeleteButton"
        onClick={() => {
          taskCardDeleteButton(taskCard.id);
        }}
      >
        <i className="fa-sharp fa-solid fa-circle-xmark"></i>
      </button>
    </div>
  );
};

export default TaskCardDeleteButton;
