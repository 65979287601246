import { v4 as uuid } from "uuid";

const TaskAddInput = (props) => {
  const { inputText, setInputText, taskList, setTaskList } = props;

  const handleChange = (e) => {
    setInputText(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputText) return;

    const taskId = uuid();
    setTaskList([
      ...taskList,
      {
        id: taskId,
        text: inputText,
        draggableId: `task-${taskId}`,
      },
    ]);
    setInputText("");
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          onChange={handleChange}
          placeholder="タスク追加"
          className="taskAddInput"
          value={inputText}
        />
      </form>
    </div>
  );
};

export default TaskAddInput;
