const Header = () => {
  return (
    <div>
      <header>
        <h1>Todo Board</h1>
      </header>
    </div>
  );
};

export default Header;
