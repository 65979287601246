import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Task from "./Task";

const Tasks = (props) => {
  const { taskList, setTaskList } = props;
  const onDragEnd = (result) => {
    const remove = taskList.splice(result.source.index, 1); // ...indexから1個削除する
    taskList.splice(result.destination.index, 0, remove[0]); // ...indexから0個要素を削除し(削除せず)、remove[0]を追加する
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {taskList.map((task, index) => (
                <div key={task.id}>
                  <Task
                    index={index}
                    task={task}
                    taskList={taskList}
                    setTaskList={setTaskList}
                  />
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Tasks;
